.player-page-container {
    display: flex;
    margin: 1rem auto;
    width: 100%;
    max-width: 600px;
}


.mt-1 {
    margin-top: 2px;
}


.quickmark-container {
    flex: 1;
    border: 3px solid;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 1;
    justify-content: center;
    align-items: center;
    font-family: monospace;
    font-size: 16px;
    text-transform: uppercase;

    overflow: auto;
    max-height: 200px;
}

@media screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
        width: 100%;
    }
}