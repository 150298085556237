.App {
    /*text-align: center;*/
    height: 100vh;
    width: 100vw;
    position: relative;
}


.center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
}

.author-info > * + * {
    padding-left: .5rem;
}